(function (window, document) {
  const counter = {
    raised: "13’837",
    counter: document.getElementById("counter"),
    state: this.counter.querySelector(".state"),
    current: this.counter.querySelector(".current"),
    setCounter: function () {
      this.state.style.width = `100%`;
      this.current.innerText = `${this.raised}.–`;
    },
  };
  counter.setCounter();

  const sostieni = {
    sostieniContainer: document.querySelector("#sostieni"),
    sostieniButtons: document.querySelectorAll(".sostieni"),
    sostieniClose: document.querySelector("#sostieni .close"),
    openSostieni: function () {
      this.sostieniContainer.classList.add("active");
    },
    closeSostieni: function () {
      this.sostieniContainer.classList.remove("active");
    },
  };
  sostieni.sostieniButtons.forEach((button) =>
    button.addEventListener("click", () => {
      sostieni.openSostieni();
    })
  );
  sostieni.sostieniClose.addEventListener("click", () => {
    sostieni.closeSostieni();
  });

  const toggleSotenitoriType = {
    privato: document.querySelector("#sostieni .nav-privato"),
    associazione: document.querySelector("#sostieni .nav-associazione"),
    form: document.querySelector("#sostieni .sostieni-form"),
    toPrivato: function () {
      console.log(this.form);
      this.form.classList.remove("associazione");
      this.form.classList.add("privato");
    },
    toAssociazione: function () {
      this.form.classList.remove("privato");
      this.form.classList.add("associazione");
    },
  };
  console.log(toggleSotenitoriType.form);
  toggleSotenitoriType.privato.addEventListener("click", () => {
    toggleSotenitoriType.toPrivato();
  });
  toggleSotenitoriType.associazione.addEventListener("click", () => {
    toggleSotenitoriType.toAssociazione();
  });
})(window, document);
